import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../../StoreContext";
import {observer} from "mobx-react";
import DonutChart from "../../../components/DonutChart";
import OutlineBox from "../../../components/OutlineBox";
import Loading from "../../../components/Loading";
import EmployeeTransactionTable from "../../../components/EmployeeTransactionTable";
import {apiGet, formatMoney, formatMessage} from "../../../utils";
import BarChart from '../../../components/BarChart';
import AlertProvider from "../../../AlertProvider";
import moment from "moment";
import {FormattedMessage, useIntl} from 'react-intl';
import EmployeeTransactionDivTable from "../../../components/EmployeeTransactionDivTable";

const MonthlyDashboard = observer(() => {
  const intl = useIntl();
  const {rootStore, authStore} = useContext(StoreContext);
  const employee = rootStore.currentEmployeeRecord;
  const [totalSavings, setTotalSavings] = useState(0);
  const [startingTotalSavings, setStartingTotalSavings] = useState(0);
  const [totalInvestments, setTotalInvestment] = useState(0);
  const [payrollSavings, setPayrollSavings] = useState(0);
  // const [emergencySavingsGoal, setEmergencySavingsGoal] = useState(0);
  const [matchSavings, setMatchSavings] = useState(0);
  const [month, setMonth] = useState(1);
  const [year, setYear] = useState(undefined);
  const [months, setMonths] = useState([]);
  const [years, setYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [endDate, setEndDate] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [cont, setCont] = useState(0);
  const [withdrawals, setWithdrawals] = useState(0);
  const [match, setMatch] = useState(0);
  const [goals, setGoals] = useState([]);
  // const [longTermGoals, setLongTermGoals] = useState([]);
  // const [shortTermGoals, setShortTermGoals] = useState([]);
  const [graphData, setGraphData] = useState(undefined);
  const [toggleGraph, setToggleGraph] = useState(false);
  const lastFour = '***-**-' + employee.ssn.split('-').pop();

  useEffect(() => {
    if (employee && year && month) {
      employee.loadMonthlyTransactions({page: 1, month: month, year: year});
    }
    getBalances();
    // getGoals();
  }, [month, year]);

  useEffect(() => {
    getYears();  
  }, []);

  useEffect(() => {
    setSummary(); 
  }, [employee.transactionPages.get(1)]);

  const setSummary = () => {
    let contribution = 0;
    let withdrawals = 0;
    let match = 0;
    let transactions = employee.transactionPages.get(1) ? employee.transactionPages.get(1) : [];
    for (let x = 0; x < transactions.length; x++) {
      contribution += transactions[x].depositAmount;
      withdrawals += (transactions[x].withdrawalAmountD 
        + transactions[x].withdrawalAmountM);
      match += transactions[x].matchAmount;
    }
    setWithdrawals(withdrawals);
    setCont(contribution);
    setMatch(match);
  }

  const getBalances = async () => {
    if (year && month) {
      const response = await apiGet(`/api/v1/monthly/get/${employee.id}/${month}/${year}`, authStore);
      const results = await response.json();
      setTotalInvestment(0);
      setTotalSavings(results.employeeRecords ? results.employeeRecords[0].totalSavings : '0');
      setStartingTotalSavings(results.employeeRecords ? results.employeeRecords[0].startingTotalSavings : '0');
      setPayrollSavings(results.employeeRecords ? results.employeeRecords[0].payrollSavings : '0');
      setMatchSavings(results.employeeRecords ? results.employeeRecords[0].matchSavings : '0');
      updateEndDate(month);
    }
    setLoading(false);
  }

  useEffect(() => {
    let short = [];
    let long = [];
    let emergency = 0;
    if (goals.length > 0) {
      for (let x = 0; x < goals.length; x++ ) {
        let goal = goals[x];
        if (goal.term === 'EMERGENCY') {
          emergency =goal.amount;
        }
        if (goal.term === 'SHORT') {
          short.push(goal);
        }
        if (goal.term === 'LONG') {
          long.push(goal);
        }
      }
      // setShortTermGoals(short);
      // setLongTermGoals(long);
      // setGraphData(goalGraphData(emergency, short, long, totalSavings));
      setToggleGraph(true);
    } else {
      setToggleGraph(false);
    }
    
  }, [goals, totalSavings]);

  // useEffect(() => {
  //      setGraphData(goalGraphData());
  // }, [emergencySavingsGoal]);

  const getGoals = async () => {
    if (year && month) {
      const response = await apiGet(`/api/v1/accountHolderGoalMonthly/${employee.id}/${month}/${year}`, authStore);
      const results = await response.json();
      setGoals(results);
    }
  }

  const updateMonth = (el) => {
    setLoading(true);
    setMonth(el.target.value);
    updateEndDate(el.target.value);
  }

  const updateEndDate = (month) => {
    const lastDay = moment(`${month}/01/${year}`).endOf('month').format('MM-DD-YYYY');
    const firstDay = moment(`${month}/01/${year}`).startOf('month').format('MM-DD-YYYY');
    setEndDate(lastDay);
    setStartDate(firstDay);
  }

  const updateYear = (el) => {
    setLoading(true);
    setYear(el.target.value);
    populateMonths(el.target.value);
  }

  const populateMonths = (year) => {
    let months = [];
    let today = moment(new Date());
    if (moment(`01/31/${year}`).isBefore(today) ) {
      months.push(1);
    }
    if (moment(`02/28/${year}`).isBefore(today) ) {
      months.push(2);
    }
    if (moment(`03/31/${year}`).isBefore(today) ) {
      months.push(3);
    }
    if (moment(`04/30/${year}`).isBefore(today) ) {
      months.push(4);
    }
    if (moment(`05/31/${year}`).isBefore(today) ) {
      months.push(5);
    }
    if (moment(`06/30/${year}`).isBefore(today) ) {
      months.push(6);
    }
    if (moment(`07/31/${year}`).isBefore(today) ) {
      months.push(7);
    }
    if (moment(`08/31/${year}`).isBefore(today) ) {
      months.push(8);
    }
    if (moment(`09/30/${year}`).isBefore(today) ) {
      months.push(9);
    }
    if (moment(`10/31/${year}`).isBefore(today) ) {
      months.push(10);
    }
    if (moment(`11/30/${year}`).isBefore(today) ) {
      months.push(11);
    }
    if (moment(`12/31/${year}`).isBefore(today) ) {
      months.push(12);
    }
    setMonths(months);
  }

  const getYears = async () => {
    const response = await apiGet(`/api/v1/payrollItem/accountHolder/interest/years/${employee.id}`, authStore);
    const results = await response.json();
    
    if (results.length === 0) {
      results.push(moment().year());
    }

    setYears(results);
    if (results.length > 0) {
      setYear(results[0]);
      populateMonths(results[0]);
    }
  }

  const goalGraphData = (emergencySavingsGoal, shortTermGoals, longTermGoals, totalSavings)  =>{
    let data = {
      labels: ['Emergency']
    };
    let savedDataset = {
      label: 'Amount Saved',
      data: [],
      backgroundColor: '#b6e174',
      minBarLength: 2,
    };
    let goalDataset = {
      label: 'Goal',
      data: [],
      backgroundColor: '#252369'
    };

    
    // If we have money stored...
    if (totalSavings) {
      // Determine if emergency goal has been realized;
      const emergencyProgress = totalSavings >= emergencySavingsGoal
        ? emergencySavingsGoal
        : totalSavings;
      savedDataset.data.push(emergencyProgress);
      goalDataset.data.push(emergencySavingsGoal);

      let remainder = Math.max(totalSavings - emergencyProgress, 0);

      // let longTermGoals = self.longTermGoals;
      // let shortTermGoals = self.shortTermGoals;

      // Short term
      const shortTermGoalCount = shortTermGoals.length;
      if (shortTermGoalCount) {
        let shortTermAmount = 0;
        shortTermGoals.forEach(goal => shortTermAmount += goal.amount);

        // If all short term goals have not been started or already been realized simply map to goal amount
        if (remainder === 0 || remainder >= shortTermAmount) {
          shortTermGoals.map(goal => {
            data.labels.push(goal.name);
            savedDataset.data.push(remainder > 0 ? goal.amount : 0);
            goalDataset.data.push(goal.amount);
          });

          // Determine remainder for long term goals
          remainder = Math.max(remainder - shortTermAmount, 0);
        } else {
          // Calculate even distribution for all goals
          const shortTermDistributed = Math.floor(remainder / shortTermGoalCount);

          shortTermGoals.map((goal, index) => {
            data.labels.push(goal.name);

            let shortGoalProgress = 0;
            // If the smallest goal has already been realized before an even distribution
            if (shortTermDistributed >= goal.amount) {
              shortGoalProgress = goal.amount;
              // Re-calc the remainder
              remainder -= goal.amount;
            } else {
              // If the smallest goal was met: remainder; else distribution
              shortGoalProgress = index > 0 ? remainder : shortTermDistributed;
            }

            savedDataset.data.push(shortGoalProgress);
            goalDataset.data.push(goal.amount);
          });

          // Zero any remainder since short term goals were not met
          remainder = 0;
        }
      }

      // Long term
      const longTermGoalCount = longTermGoals.length;
      if (longTermGoalCount) {
        let longTermAmount = 0;
        longTermGoals.forEach(goal => longTermAmount += goal.amount);

        // If all long term goals have been realized simply map to goal amount
        if (remainder === 0 || remainder >= longTermAmount) {
          longTermGoals.map(goal => {
            data.labels.push(goal.name);
            savedDataset.data.push(remainder > 0 ? goal.amount : 0);
            goalDataset.data.push(goal.amount);
          });
        } else {
          // Calculate even distribution for all goals
          const longTermDistributed = Math.floor(remainder / longTermGoalCount);

          longTermGoals.map((goal, index) => {
            // Push in the goal amount
            data.labels.push(goal.name);

            let longGoalProgress = 0;
            // If the smallest goal has already been realized before an even distribution
            if (longTermDistributed >= goal.amount) {
              longGoalProgress = goal.amount;
              // Re-calc the remainder
              remainder -= goal.amount;
            } else {
              // If the smallest goal was met: remainder; else distribution
              longGoalProgress = index > 0 ? remainder : longTermDistributed;
            }

            savedDataset.data.push(longGoalProgress);
            goalDataset.data.push(goal.amount);
          });
        }
      }
    }

    data.datasets = [savedDataset, goalDataset];

    return data;
  }

  return <div>
    <br/>
    {loading ? 
    <Loading/>  
    : ''
    }

    <div className={'flex items-center mb-5'}>
      <h2 className={"text-xl mr-1"}><FormattedMessage id={'report.monthly.welcome'}/> {employee ? employee.firstName: ''}</h2>
      <h2 className={"text-xl"}><FormattedMessage id={'report.monthly.acct'}/> {employee ?  lastFour: ''}</h2>
    </div>
    <div className={'flex items-center mb-6'}>
        <label><FormattedMessage id={'report.label.monthly'}/></label>
        <select onChange={updateMonth} value={month} name="payrollBankConnectionId" className='border border-mid-grey p-3 mx-3 w-24 sm:w-32'>
            {months.map((month) =>
                <option value={month}>{month}</option>
            )}
        </select>
        <label className={'mx-4'}><FormattedMessage id={'report.label.year'}/></label>
        <select onChange={updateYear} value={year} name="payrollBankConnectionId" className='border border-mid-grey p-3 w-24 sm:w-32'>
            {years.map((year) =>
                <option value={year}>{year}</option>
            )}
        </select>
    </div>
    <AlertProvider/>
    <OutlineBox className={'flex-1'}>
      <div className={"border-solid border-gray-200 border-b-2 p-6"}>
        
        <div>
          <div className={'flex'}>
            <h3 className={'flex-1 text-lg'}><FormattedMessage id={'report.label.balance'}/>{endDate}</h3>
          </div>
          <span className={"text-4xl font-thin"}>
            {formatMoney(totalSavings + totalInvestments)}
          </span>
        </div>
        <div className={"flex flex-col xl:flex-row mt-8 print:flex-row"}>
          <DonutChart
            data={[
              {
                label: formatMessage(intl, 'report.label.cash', '', []),
                value: totalSavings,
                color: "#ff8700"
              },
              {
                label: formatMessage(intl, 'report.label.investments', '', []),
                value: totalInvestments,
                color: "#ffc380"
              }
            ]}
            formatValue={formatMoney}
            legendTitle={formatMessage(intl, 'report.label.total.balance', '', [])}
            IsFixedWidth={true}
            marginClass={'mr-16 xl:mr-16'}
          />
          <DonutChart
            data={[
              {
                label: formatMessage(intl, 'report.label.ee', '', []),
                value: payrollSavings,
                color: "#252369"
              },
              {
                label: formatMessage(intl, 'report.label.er', '', []),
                value: matchSavings,
                color: "#81c1ff"
              },
              {
                label: formatMessage(intl, 'report.label.other', '', []),
                value: totalInvestments,
                color: "#0057ff"
              }
            ]}
            formatValue={formatMoney}
            legendTitle={formatMessage(intl, 'report.label.acct.balance', '', [])}
            IsFixedWidth={true}
          />
        </div>
      </div>
      {/* <div className={'p-6'}>
        <div className={'flex'}>
          <h3 className={'flex-1 text-lg'}>My Savings Goals</h3>
        </div>
        {toggleGraph ?
          <BarChart
            data={graphData}
            formatValue={formatMoney}
          />
          : 
          <div className={"mt-6"}>
            <p className={'flex-1 text-red-500'}>Goals Were Not Defined For This Month</p>
          </div>
        }
      </div> */}
    </OutlineBox>
    <div className={"mt-6"}>
      <OutlineBox className={"flex-1 p-4 w-full"}>
        <h3 className={'flex-1 text-xl mb-3'}><FormattedMessage id={'report.label.summary'}/></h3>
        <div className={'flex'}>
          <p className={'flex-1'}><FormattedMessage id={'report.label.starting'}/> {startDate}</p>
          <p className={'text-lg'}>{formatMoney(startingTotalSavings + totalInvestments)}</p>
        </div>
        <div className={'flex bg-subtle-blue'}>
          <p className={'flex-1'}><FormattedMessage id={'report.label.contributions'}/></p>
          <p className={'text-lg'}>{formatMoney(cont)}</p>
        </div>
        <div className={'flex'}>
          <p className={'flex-1'}><FormattedMessage id={'report.label.matches'}/></p>
          <p className={'text-lg'}>{formatMoney(match)}</p>
        </div>
        <div className={'flex bg-subtle-blue'}>
          <p className={'flex-1'}><FormattedMessage id={'report.label.withdrawals'}/></p>
          <p className={'text-lg'}>{formatMoney(withdrawals)}</p>
        </div>
        <div className={'flex'}>
          <p className={'flex-1'}><FormattedMessage id={'report.label.ending'}/> {endDate}</p>
          <p className={'text-lg'}>{formatMoney(totalSavings + totalInvestments)}</p>
        </div>
      </OutlineBox>
    </div>
    <div className={"mt-12"}>
      <div className={"flex flex-row"}>
        <span className={"text-xl flex-1"}><FormattedMessage id={'report.label.transactions'}/></span>
      </div>
      {/* {employee.transactionPages && <EmployeeTransactionTable transactions={employee.transactionPages.get(1)}/>} */}
      {employee.transactionPages && <EmployeeTransactionDivTable transactions={employee.transactionPages.get(1)}/>}
    </div>

    <br/><br/><br/><br/>
    <div className={"mt-12"}>
        <span className={"text-xl flex-1"}><FormattedMessage id={'need.help'}/>
          &nbsp; <FormattedMessage id={'email'}/> or <FormattedMessage id={'phone'}/>
        </span>
    </div>
  </div>;
});

export default MonthlyDashboard;
