import React from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState } from 'react'
import PrimaryButton from './PrimaryButton';
import { FormattedMessage } from 'react-intl';

export default function DashboardCheckListDialog({openDefault, showOpenButton, openText, user, dialogTitle, items, onClose}) {
  const [isOpen, setIsOpen] = useState(openDefault);

  function closeModal() {
    setIsOpen(false);
    //onClose(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
        { showOpenButton ? 
      <div className="fixed inset-0 flex items-center justify-center">
        <button
          type="button"
          onClick={openModal}
          className="focus:outline-none rounded-md bg-black bg-opacity-20 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        >
            {openText}
        </button>
      </div>
      : ''}

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10 dashboardbox" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {dialogTitle}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      <FormattedMessage id={'dashboard.checklist.dialog.welcome'}/> {user.name ? user.name : user.fullName}!
                      <br/>
                      <br/>
                      <FormattedMessage id={'dashboard.checklist.dialog.instructions'}/>
                      <br/>
                      <br/>
                    </p>
                    {items ?
                      <ul>
                      {items.map((item, idx) => 
                          <li key={idx}>{idx+1}. {item}</li>
                      )}
                      </ul>
                       : ''}
                  </div>

                  <div className="mt-4">
                    {/* <button
                      type="button"
                      className="focus:outline-none inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      OK
                    </button> */}
                    <PrimaryButton type={'button'} className={'pr-6'}
                        onClick={closeModal}><FormattedMessage id={'ok.button'}/></PrimaryButton>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
