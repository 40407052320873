import React, {useEffect, useContext, useState} from "react";
import StoreContext from "../../StoreContext";
import {observer} from "mobx-react";
import DonutChart from "../../components/DonutChart";
import OutlineBox from "../../components/OutlineBox";
import {Link, Redirect, useRouteMatch} from "react-router-dom";
import Loading from "../../components/Loading";
// import EmployeeTransactionTable from "../../components/EmployeeTransactionTable";
import EmployeeTransactionDivTable from "../../components/EmployeeTransactionDivTable";
import {apiGet, formatMoney} from "../../utils";
import BarChart from '../../components/BarChart';
import BarChartSmall from '../../components/BarChartSmall';
import DashboardSavingsAmountSelector from "../../components/DashboardSavingsAmountSelector";
import DashboardCheckListDialog from "../../components/DashboardCheckListDialog";
import DashboardTerminationDialog from "../../components/DashboardTerminationDialog";
import AlertProvider from "../../AlertProvider";
import PrimaryButton from "../../components/PrimaryButton";
import { FormattedMessage, useIntl} from 'react-intl';
import {formatMessage} from '../../utils';
import { Box, Grid, Step, StepLabel, Stepper } from "@material-ui/core";
import AcknowledgmentDialog from "../../components/AcknowledgmentDialog";

const Dashboard = observer(() => {
  const {rootStore, authStore} = useContext(StoreContext);
  const employee = rootStore.currentEmployeeRecord;
  let {  url } = useRouteMatch();
  const [showTerm, setShowTerm] = useState(false);
  const [termRead, setTermRead] = useState(false);
  const [showSetup, setShowSetup] = useState(false);
  const [setupRead, setSetupRead] = useState(false);
  const [showAck, setShowAck] = useState(false);
  const [graphdata, setGraphdata] = useState(undefined);
  const [useMobile, setUseMobile] = useState(window.innerWidth <= 728);
  const [spendable, setSpendable] = useState(0);
  

  const intl = useIntl();

  useEffect(() => {
    if (employee) {
      setGraphdata(goalGraphData());
      getSpendable();
      isNewAcks();
    }

  }, [employee])
  
  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 728) {
      console.log(' is mobile ');
    }
    setUseMobile(window.innerWidth <= 728);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  useEffect(() => {
    if (employee) {
      employee.loadTransactions({page: 1});
      employee.checkSetup(employee.id);

      if (employee.setupItems && employee.setupItems.length > 0) {
        setShowSetup(true);
      }
      if (employee.status === 'TERMINATED' ) {
        setShowTerm(true);
      }
    }

  }, []);

  const closeTerm = () => {
    setShowTerm(false);
    setTermRead(true);
    if (!setupRead && employee.setupItems && employee.setupItems.length > 0) {
      setShowSetup(true);
    }
  }

  const closeSetup = () => {
    setShowSetup(false);
    setSetupRead(true);
    if (!termRead && employee.status === 'TERMINATED' ) {
      setShowTerm(true);
    }
  }

  const getSpendable = async () => {
    const response = await apiGet(`/api/v1/accountHolder/spendable/${employee.id}`, authStore);
    
    const resp = await response.json();

    if (resp.amount) {
      setSpendable(resp.amount);
    }
  }

  const isNewAcks = async () => {
    const response = await apiGet(`/api/v1/user/${employee.id}/newAcks`, authStore);
    
    const resp = await response.json();

    if (resp.newAcks === true && rootStore.acksShown === false) {
      setShowAck(true);
      rootStore.setShowedAcks();
     }
  }

  const print = () => {
    window.print();
  }

  if (!employee && rootStore.loading) {
    return <Loading/>;
  }
  
  if (!employee || !employee.verified) {
   return <Redirect to={`/verify`}/>;
  }

  

  const totalSavings = employee.totalSavings;//TODO get total from new service
  // const totalSavings = 100.00;
  const totalInvestments = 0;//employee.stats.get('total_investments');//TODO get total from new service
  // const totalInvestments = 100.00;

  const goalGraphData = ()  =>{
    let data = {
      labels: [formatMessage(intl, 'report.label.emergency', '', [])]
    };
    let savedDataset = {
      label: formatMessage(intl, 'report.label.saved', '', []),
      data: [],
      backgroundColor: '#b6e174',
      minBarLength: 2,
    };
    let goalDataset = {
      label: formatMessage(intl, 'report.label.goal', '', []),
      data: [],
      backgroundColor: '#252369'
    };

    
    // If we have money stored...
    // if (totalSavings) {
      // Determine if emergency goal has been realized;
      const emergencyProgress = totalSavings >= employee.emergencySavingsGoal
        ? employee.emergencySavingsGoal
        : totalSavings;
      savedDataset.data.push(emergencyProgress);
      goalDataset.data.push(employee.emergencySavingsGoal);

      let remainder = Math.max(totalSavings - emergencyProgress, 0);

      // let longTermGoals = self.longTermGoals;
      // let shortTermGoals = self.shortTermGoals;

      // Short term
      const shortTermGoalCount = employee.shortTermGoals.length;
      if (shortTermGoalCount) {
        let shortTermAmount = 0;
        employee.shortTermGoals.forEach(goal => shortTermAmount += goal.amount);

        // If all short term goals have not been started or already been realized simply map to goal amount
        if (remainder === 0 || remainder >= shortTermAmount) {
          employee.shortTermGoals.map(goal => {
            data.labels.push(formatMessage(intl, goal.name, '', []) );
            savedDataset.data.push(remainder > 0 ? goal.amount : 0);
            goalDataset.data.push(goal.amount);
          });

          // Determine remainder for long term goals
          remainder = Math.max(remainder - shortTermAmount, 0);
        } else {
          // Calculate even distribution for all goals
          const shortTermDistributed = Math.floor(remainder / shortTermGoalCount);

          employee.shortTermGoals.map((goal, index) => {
            data.labels.push(formatMessage(intl, goal.name, '', []) );

            let shortGoalProgress = 0;
            // If the smallest goal has already been realized before an even distribution
            if (shortTermDistributed >= goal.amount) {
              shortGoalProgress = goal.amount;
              // Re-calc the remainder
              remainder -= goal.amount;
            } else {
              // If the smallest goal was met: remainder; else distribution
              shortGoalProgress = index > 0 ? remainder : shortTermDistributed;
            }

            savedDataset.data.push(shortGoalProgress);
            goalDataset.data.push(goal.amount);
          });

          // Zero any remainder since short term goals were not met
          remainder = 0;
        }
      }

      // Long term
      const longTermGoalCount = employee.longTermGoals.length;
      if (longTermGoalCount) {
        let longTermAmount = 0;
        employee.longTermGoals.forEach(goal => longTermAmount += goal.amount);

        // If all long term goals have been realized simply map to goal amount
        if (remainder === 0 || remainder >= longTermAmount) {
          employee.longTermGoals.map(goal => {
            data.labels.push(formatMessage(intl, goal.name, '', []) );
            savedDataset.data.push(remainder > 0 ? goal.amount : 0);
            goalDataset.data.push(goal.amount);
          });
        } else {
          // Calculate even distribution for all goals
          const longTermDistributed = Math.floor(remainder / longTermGoalCount);

          employee.longTermGoals.map((goal, index) => {
            // Push in the goal amount
            data.labels.push(formatMessage(intl, goal.name, '', []) );

            let longGoalProgress = 0;
            // If the smallest goal has already been realized before an even distribution
            if (longTermDistributed >= goal.amount) {
              longGoalProgress = goal.amount;
              // Re-calc the remainder
              remainder -= goal.amount;
            } else {
              // If the smallest goal was met: remainder; else distribution
              longGoalProgress = index > 0 ? remainder : longTermDistributed;
            }

            savedDataset.data.push(longGoalProgress);
            goalDataset.data.push(goal.amount);
          });
        }
      }
    // }

    data.datasets = [savedDataset, goalDataset];

    return data;
  }

  

  return <div>
    {showAck ? 
    <AcknowledgmentDialog />
    : ''}
    <div className="d-inline-block">
      <span className="float-left">
        <h2 className={"text-xl mb-10 print:mb-4"}><FormattedMessage id={'dashboard.welcome'}/> {employee && employee.firstName}</h2>
      </span>
      <span className="float-right">
        <PrimaryButton onClick={() => print()}><FormattedMessage id={'dashboard.print'}/></PrimaryButton>
      </span>
    </div>
    <AlertProvider/>
      
    {showTerm && rootStore.currentUser === 'EMPLOYEE' ?

      <DashboardTerminationDialog  user={employee} dialogTitle={formatMessage(intl, 'dashboard.dialog.termination', '', [])} 
      openDefault={true} showOpenButton={false} openText={''} onClose={closeTerm}/>
      // : showSetup ?
      //   <DashboardCheckListDialog user={employee} dialogTitle={formatMessage(intl, 'dashboard.dialog.welcome', '', [])} 
      //     openDefault={true} showOpenButton={false} openText={''} items={employee.setupItems} onClose={closeSetup}/>
        : ''
    }
    {showTerm && rootStore.currentUser === 'EMPLOYEE' ?

    <DashboardTerminationDialog  user={employee} dialogTitle={formatMessage(intl, 'dashboard.dialog.termination', '', [])} 
    openDefault={true} showOpenButton={false} openText={''} onClose={closeTerm}/>
    // : showSetup ?
    //   <DashboardCheckListDialog user={employee} dialogTitle={formatMessage(intl, 'dashboard.dialog.welcome', '', [])} 
    //     openDefault={true} showOpenButton={false} openText={''} items={employee.setupItems} onClose={closeSetup}/>
      : ''
    }
    {/* {showSetup ?
    <DashboardCheckListDialog user={employee} dialogTitle={'Welcome!'} 
      openDefault={true} showOpenButton={false} openText={''} items={employee.setupItems} onClose={setShowSetup}/>
    : ''} */}
    <OutlineBox className={'flex-1'}>
      <div className={'border-solid border-gray-200 border-b-2 p-6'}>
        <DashboardSavingsAmountSelector employee={employee}/>
      </div>
      <div className={"border-solid border-gray-200 border-b-2 p-6"}>

        <Grid container
                spacing={12}>
          <Grid item md={5} lg={5} xs={6}>
            <h3 className={'text-lg'}><FormattedMessage id={'dashboard.account.balance'}/></h3>
            <span className={"text-4xl font-thin"}>
              {formatMoney(totalSavings + totalInvestments)}
            </span>
          </Grid>
          <Grid item md={5} lg={5} xs={6}>
            <Grid container>
              <Grid item xs={12} sm={5} md={6}>
              <h3 className={'text-lg'}><FormattedMessage id={'dashboard.account.open'}/>*</h3>
              <span className={"text-4xl font-thin"}>
                {formatMoney(spendable)}
              </span>
              </Grid>
              <Grid item xs={3} sm={4} md={3}>
              <span className={"text-sm withdrawLink"}>
              *<FormattedMessage id={'dashboard.account.open.msg'}/>
              </span>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={2} lg={2} xs={0} className={'withdrawLink'}>
              <div>
              <Link to={`${url}/withdraw`} className={"text-primary underline"}><FormattedMessage id={'dashboard.withdrawal'}/></Link>
              </div>
          </Grid>
        </Grid>
        <div className={"flex flex-col lg:flex-row mt-8 print:flex-row"}>
          <DonutChart
            data={[
              {
                label: formatMessage(intl, 'dashboard.donut.cash', '', []),
                value: totalSavings,
                color: "#ff8700"
              },
              {
                label: formatMessage(intl, 'dashboard.donut.investment', '', []),
                value: totalInvestments,
                color: "#ffc380"
              }
            ]}
            formatValue={formatMoney}
            legendTitle={formatMessage(intl, 'dashboard.donut.balance', '', [])}
             IsFixedWidth={true}
             marginClass={'mr-16 lg:mr-16'}
          />
          <DonutChart
            data={[
              {
                label: formatMessage(intl, 'dashboard.donut.employee', '', []),
                value: employee.payrollSavings,
                color: "#252369"
              },
              {
                label: formatMessage(intl, 'dashboard.donut.employer', '', []),
                value: employee.matchSavings,
                color: "#81c1ff"
              },
              {
                label: formatMessage(intl, 'dashboard.donut.other', '', []),
                value: totalInvestments,
                color: "#0057ff"
              }
            ]}
            formatValue={formatMoney}
            legendTitle={formatMessage(intl, 'dashboard.donut.balance.source', '', [])}
            IsFixedWidth={true}
          />
        </div>
      </div>
      
        {useMobile === false ?
        <div className={'p-6'}>
        <div className={'flex'}>
          <h3 className={'flex-1 text-lg'}><FormattedMessage id={'dashboard.savings.goals'}/></h3>
          <div>
            <Link to={`${url}/settings/plan`} className={"text-primary underline"}><FormattedMessage id={'dashboard.edit.goals'}/></Link>
          </div>
        </div>
          <BarChart
            data={graphdata}
            formatValue={formatMoney}
          />
        </div>
        : <div className={'p-6 min-barchart-hgt'}>
        <div className={'flex'}>
          <h3 className={'flex-1 text-lg'}><FormattedMessage id={'dashboard.savings.goals'}/></h3>
          <div>
            <Link to={`${url}/settings/plan`} className={"text-primary underline"}><FormattedMessage id={'dashboard.edit.goals'}/></Link>
          </div>
        </div>
          <BarChartSmall
            data={graphdata}
            formatValue={formatMoney}
          />
        </div>
        }
      
    </OutlineBox>
    <div className={"mt-12"}>
      <div className={"flex flex-row"}>
        <span className={"text-xl flex-1"}><FormattedMessage id={'dashboard.recent.transactions'}/></span>

        <Link to={ `${url}/transactions`} className={"text-primary"}><FormattedMessage id={'dashboard.all.transactions'}/></Link>
      </div>
      {/* {employee.transactionPages && <EmployeeTransactionTable transactions={employee.transactionPages.get(1)}/>} */}
      {employee.transactionPages && <EmployeeTransactionDivTable transactions={employee.transactionPages.get(1)}/>}
    </div>
  </div>;
});

export default Dashboard;
